// GlobalState.js
import React, { useState } from 'react';

const GlobalStateContext = React.createContext();

export const GlobalStateProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userIsSignedIn, setUserIsSignedIn] = useState(false); // [user._id]: true/false

  return (
    <GlobalStateContext.Provider 
      value={{ 
        user, 
        userIsSignedIn,
        signInUser: (user) => {
          setUser(user);
          setUserIsSignedIn(true);
        },
        userSignOut: () => { 
          setUser(null);
          setUserIsSignedIn(false);
          localStorage.removeItem('token');
          localStorage.removeItem('id');
        },
      }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => {
  const context = React.useContext(GlobalStateContext);
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalStateProvider');
  }
  return context;
};

export default GlobalStateContext;