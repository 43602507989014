import axios from "axios";

const BASE = process.env.REACT_APP_API_BASE;

console.log({BASE});

/**
 * custom rule for local handling for any routes pointed to new services 
 * production is routed via nginx
 */ 
function constructEndpoint(route) {
  if (BASE.indexOf('localhost') === -1) return `${BASE}${route}`
  else return `${BASE}:3001${route}`;
}

export async function post(route, payload, config = {}) {
  const endpoint = constructEndpoint(route);
  const response = await axios.post(endpoint, payload, config)
    .catch((err) => {
      if (err.response && err.response.data) throw new Error(err.response.data);
      throw new Error(err);
    });
  return response.data;
}

export async function get(route, config = {}) {
  const endpoint = constructEndpoint(route);
  const response = await axios.get(endpoint, config)
    .catch(err => {
      if (err.response && err.response.data) throw new Error(err.response.data);
      throw new Error(err);
    });
  return response.data;
}

export async function patch(route, payload, config = {}) {
  const endpoint = constructEndpoint(route);
  const response = await axios.patch(endpoint, payload, config)
    .catch(err => {
      if (err.response && err.response.data) throw new Error(err.response.data);
      throw new Error(err);
    });
  return response.data;
}

// module.exports = { 
//   post, 
//   get, 
//   patch,
// };