import React, { useState, useEffect } from 'react';
import { post } from '../api';

import { useGlobalState } from '../context/GlobalState';

import SignIn from '../components/SignIn';
import DashboardDetails from '../components/DashboardDetails';

import LoadingSpinner from '../components/LoadingSpinner';


import './Dashboard.css';

const Dashboard = ({ isMobile }) => {
  const { user } = useGlobalState();
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    getDashboardDetails();
  }, []);

  const getDashboardDetails = async () => {
    setIsLoading(true);
    
    const userId = localStorage.getItem('id');
    const token = localStorage.getItem('token');

    const payload = {
      user: userId,
      token: token,
    }

    const dashboardDataPayload = await post('/cmsui/get_dashboard', payload)
      .catch(err => {
        console.log({err});
      });
      
    setDashboardData(dashboardDataPayload);

    console.log({dashboardDataPayload});
    
    setIsLoading(false);
  };

  const refreshDashboard = async () => {
    getDashboardDetails();
  }

  if (!user) {
    return (
      <div>
        <SignIn isMobile={isMobile}/>
      </div>
    )
  }

  return (
    <div>
      {
        isLoading ? <LoadingSpinner /> :
        <div className={isMobile ? 'page-mobile' : 'page'}>
          <h1>Dashboard</h1>          
          <button onClick={refreshDashboard}>Refresh</button>
          {
            dashboardData
            ?
            <div className='stat-row'>
              <div className='stat-column'>
                {
                  Object.keys(dashboardData).map((key, index) => {
                    // if index is even then return
                    if (index >= 4) return;
                    const value = dashboardData[key];
                    return (
                      <DashboardDetails key={key} prop={key} value={value} />
                    )
                  })
                }
              </div>
              <div className='stat-column'>
                {
                  Object.keys(dashboardData).map((key, index) => {
                    // if index is odd then return
                    if (index < 4) return;
                    const value = dashboardData[key];
                    return (
                      <DashboardDetails key={key} prop={key} value={value} />
                    )
                  })
                }
              </div>
            </div>
            :
            <h1>No Data Found</h1>          
          }
        </div>
      }
    </div>

  );
};

export default Dashboard;
