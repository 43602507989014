import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { GlobalStateProvider } from './context/GlobalState';

import './App.css';

import Home from './screens/Home';
import CourseDetail from './screens/CourseDetail';
import Dashboard from './screens/Dashboard';
import Header from './components/Header';

function App() {
  return (
    <GlobalStateProvider>
      <div className="App">
        <Router>
          <Header isMobile={isMobile} />
          <Routes>
            <Route path="/" element={<Home  isMobile={isMobile} />} />
            <Route path="/course/:courseNumber" element={<CourseDetail isMobile={isMobile} />} />
            <Route path="/dashboard" element={<Dashboard isMobile={isMobile} />} />
          </Routes>
        </Router>
      </div>
    </GlobalStateProvider>
  );
}

export default App;
