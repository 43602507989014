import React, { useState } from 'react';
import { post } from '../api';

import { useGlobalState } from '../context/GlobalState';

import SignIn from '../components/SignIn';
import CourseList from '../components/CourseList';

import './Home.css';

const Home = ({ isMobile }) => {
  const { user } = useGlobalState();

  return (
    <div className={isMobile ? 'page-mobile' : 'page'}>
      {
        user 
        ?
        <CourseList isMobile={isMobile}/>
        :
        <SignIn isMobile={isMobile}/>
      }
    </div>
  );
};

export default Home;