import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { post } from '../api';

import { useGlobalState } from '../context/GlobalState';

import LoadingSpinner from '../components/LoadingSpinner';
import SignIn from '../components/SignIn';
import ErrorModal from '../components/ErrorModal';
import DropdownSelect from '../components/DropdownSelect';
import ActiveToggle from '../components/ActiveToggle';

import './CourseDetail.css';
import { set } from 'ramda';

import { countries, continents } from '../context/CountriesAndContinents';

const countryOptions = countries.map(country => ({
  value: country.code,
  label: `${country.name} (${country.code})`
}));

const continentOptions = continents.map(continent => ({
  value: continent,
  label: continent,
}));

const CourseDetail = ({ isMobile }) => {
  let { courseNumber } = useParams(); // Extracting courseNumber from the route parameters
  const { user } = useGlobalState();
  const [isLoading, setIsLoading] = useState(false);
  const [courseDetails, setCourseDetails] = useState({});
  const [courseName, setCourseName] = useState('');
  const [description, setDescription] = useState('');
  const [architect, setArchitect] = useState('');
  const [yearBuilt, setYearBuilt] = useState('');
  const [clubName, setClubName] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState('');
  const [continent, setContinent] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [active, setActive] = useState('');
  const [image, setImage] = useState('');
  const [website, setWebsite] = useState('');
  const [error, setError] = useState(null);
  const [bannerMessage, setBannerMessage] = useState('');
  const [bannerVisible, setBannerVisible] = useState(false);

  useEffect(() => {
    getCourseDetails();
  }, []);

  const getCourseDetails = async () => {
    setIsLoading(true);
    
    const payload = {
      _id: courseNumber,
    }

    const courseData = await post('/cmsui/course', payload)
      .catch(err => {
        console.log({err});
      });
      
    if (courseData) {
      setCourseDetails(courseData);
      setCourseName(courseData.course_name);
      setDescription(courseData.description || '');
      setArchitect(courseData.architect || '');
      setYearBuilt(courseData.year_built || '');
      setClubName(courseData.club_name || '');
      setCity(courseData.city || '');
      setState(courseData.state || '');
      setZip(courseData.zip || '');
      setCountry(courseData.country || '');
      setContinent(courseData.continent || '');
      setPhone(courseData.phone_number || '');
      setEmail(courseData.email || '');
      setLatitude(courseData.latitude || '');
      setLongitude(courseData.longitude || '');
      setActive(courseData.active || false);
      setImage(courseData.image || '');
      setWebsite(courseData.website || '');
    }
    setIsLoading(false);
  };

  const handleChange = (e, setValue, fieldName) => {
    setValue(e.target.value);
    if (e.key === 'Enter') {
      handleBlur({ [fieldName]: e.target.value });
      e.target.blur();  // Deselect the input
    }
  };

  const handleCountryChange = async selectedOption => {
    const value = selectedOption ? selectedOption.value : '';
    const data = { country: value };
    await handleBlur(data).then(() => {
      setCountry(value);
    });
  };

  const handleContinentChange = async selectedOption => {
    const value = selectedOption ? selectedOption.value : '';
    const data = { continent: value };
    await handleBlur(data).then(() => {
      setContinent(value);
    });
  };

  const handleActiveChange = async isActive => {
    const data = { active: isActive };
    await handleBlur(data).then(() => {
      setActive(isActive);
    });
  };

  const handleCopyTees = async teeId => {
    const payload = {
      course_id: courseNumber,
      tee_id: teeId,
    }

    const courseData = await post('/cmsui/copy_tees', payload)
      .catch(err => {
        console.log({err});
      });

    if (courseData) {
      setCourseDetails(courseData);
      setCourseName(courseData.course_name);
      setDescription(courseData.description || '');
      setArchitect(courseData.architect || '');
      setYearBuilt(courseData.year_built || '');
      setClubName(courseData.club_name || '');
      setCity(courseData.city || '');
      setState(courseData.state || '');
      setZip(courseData.zip || '');
      setCountry(courseData.country || '');
      setContinent(courseData.continent || '');
      setPhone(courseData.phone_number || '');
      setEmail(courseData.email || '');
      setLatitude(courseData.latitude || '');
      setLongitude(courseData.longitude || '');
      setActive(courseData.active || false);
      setImage(courseData.image || '');
      setWebsite(courseData.website || '');
    } 
  };

  // Handle blur to switch back to display mode and fire the completion event
  const handleBlur = async (data) => {
    const field = Object.keys(data)[0];

    if (data[field] !== courseDetails[field]) {
      // editing is complete and api call needs to be triggered
      const payload = {
        _id: courseNumber,
        data: data,
      };

      try {
        const response = await post('/cmsui/update_course', payload);
        setBannerMessage('Update Successful');
        setBannerVisible(true);
        setTimeout(() => setBannerVisible(false), 3000);
      } catch (err) {
        console.error('Update failed:', err);
        setBannerMessage('Update Failed');
        setBannerVisible(true);
        setTimeout(() => setBannerVisible(false), 3000);
        setError(err.message);
      }
    }
  };

  const handleTableChange = (e, type, teeId, value, holeIndex = null) => {
    if (e.key === 'Enter') {
      handleTableBlur(type, teeId, value, holeIndex);
      e.target.blur();  // Deselect the input
    } else {
      const courseDetailsCopy = { ...courseDetails };
      if (type === 'tee') {
        courseDetailsCopy.tees[teeId][value] = e.target.value;
      } else if (type === 'hole') {
        courseDetailsCopy.tees[teeId].holes[holeIndex][value] = e.target.value;
      }
      setCourseDetails(courseDetailsCopy);
    }
  };  

  const handleTableBlur = async (type, teeId, property, holeIndex = null) => {
    let newValue;
    let holeId;
    if (type === 'tee') {
      newValue = courseDetails.tees[teeId][property];
    } else if (type === 'hole') {
      newValue = courseDetails.tees[teeId].holes[holeIndex][property];
      holeId = courseDetails.tees[teeId].holes[holeIndex]._id;
    }
  
    const payload = {
      _id: type === 'tee' ? teeId : holeId,
      data: { [property]: newValue }
    };
  
    try {
      const endpoint = type === 'tee' ? '/cmsui/update_tees' : '/cmsui/update_holes';
      await post(endpoint, payload);
      setBannerMessage('Update Successful');
      setBannerVisible(true);
      setTimeout(() => setBannerVisible(false), 3000);
    } catch (err) {
      console.error('Update failed:', err);
      setBannerMessage('Update Failed');
      setBannerVisible(true);
      setTimeout(() => setBannerVisible(false), 3000);
      setError(err.message);
    }
  };

  const closeErrorModal = () => setError(null);

  function extractErrorMessage(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const errorText = doc.body.textContent || "Unknown error occurred";
    // Extract only the first line or part of the error message before the stack trace.
    const firstLine = errorText.split('(')[0]
      .replace('Error: ', '')
      .replace('at updateCourseDetails', '')
      .replace('at updateTees', '')
      .replace('at updateHoles', '')
      .trim();
    return firstLine;
  }

  const renderHeader = () => {
    return (
      <form className="detailHeader">
        <h1>Course Details</h1>
        <div className="formRow">
          <div className="mainFormColumn">
            <label>COURSE NAME</label>
            <input
              type="text"
              className="courseNameInput"
              placeholder='Add Course Name'
              autoComplete="off"
              spellCheck="off" 
              autoCapitalize="off"
              value={courseName}
              onChange={(e) => handleChange(e, setCourseName, 'name')}
              onKeyPress={(e) => handleChange(e, setCourseName, 'name')}
              onBlur={() => handleBlur({ 'name': courseName })}
            />
            <label>CLUB NAME</label>
            <input
              type="text"
              className="clubNameInput"
              placeholder='Add Club Name'
              autoComplete="off"
              spellCheck="off" 
              autoCapitalize="off"
              value={clubName}
              onChange={(e) => handleChange(e, setClubName, 'club_name')}
              onKeyPress={(e) => handleChange(e, setClubName, 'club_name')}
              onBlur={() => handleBlur({ 'club_name': clubName })}
            />
            <label>DESCRIPTION</label>
            <textarea
              rows="4"
              className="descriptionInput"
              placeholder='Add Description'
              autoComplete="off"
              spellCheck="off" 
              autoCapitalize="off"
              resize="none"
              value={description}
              onChange={(e) => handleChange(e, setDescription, 'description')}
              onKeyPress={(e) => handleChange(e, setDescription, 'description')}
              onBlur={() => handleBlur({ 'description': description })}
            />
            <label>ARCHITECT</label>
            <input
              type="text"
              className="normalInput"
              placeholder='Add Architect'
              autoComplete="off"
              spellCheck="off" 
              autoCapitalize="off"
              value={architect}
              onChange={(e) => handleChange(e, setArchitect, 'architect')}
              onKeyPress={(e) => handleChange(e, setArchitect, 'architect')}
              onBlur={() => handleBlur({ 'architect': architect })}
            />
            <label>YEAR BUILT</label>
            <input
              type="text"
              className="normalInput"
              placeholder='Add Year Built'
              autoComplete="off"
              spellCheck="off" 
              autoCapitalize="off"
              value={yearBuilt}
              onChange={(e) => handleChange(e, setYearBuilt, 'year_built')}
              onKeyPress={(e) => handleChange(e, setYearBuilt, 'year_built')}
              onBlur={() => handleBlur({ 'year_built': yearBuilt })}
            />
            <label>ACTIVE</label>
            <ActiveToggle isActive={active} onChange={handleActiveChange} />
            <label>IMAGE</label>
            <input
              type="text"
              className="normalInput"
              placeholder='Add Image'
              autoComplete="off"
              spellCheck="off" 
              autoCapitalize="off"
              value={image}
              onChange={(e) => handleChange(e, setImage, 'image')}
              onKeyPress={(e) => handleChange(e, setImage, 'image')}
              onBlur={() => handleBlur({ 'image': image })}
            />
            <label>WEBSITE</label>
            <input
              type="text"
              className="normalInput"
              placeholder='Add Website'
              autoComplete="off"
              spellCheck="off" 
              autoCapitalize="off"
              value={website}
              onChange={(e) => handleChange(e, setWebsite, 'website')}
              onKeyPress={(e) => handleChange(e, setWebsite, 'website')}
              onBlur={() => handleBlur({ 'website': website })}
            />
          </div>
          <div className="secondaryFormColumn">
            <label>CITY</label>
            <input
              type="text"
              className="addressInput"
              placeholder='Add City'
              autoComplete="off"
              spellCheck="off" 
              autoCapitalize="off"
              value={city}
              onChange={(e) => handleChange(e, setCity, 'city')}
              onKeyPress={(e) => handleChange(e, setCity, 'city')}
              onBlur={() => handleBlur({ 'city': city })}
            />
            <label>STATE</label>
            <input
              type="text"
              className="addressInput"
              placeholder='Add State'
              autoComplete="off"
              spellCheck="off" 
              autoCapitalize="off"
              value={state}
              onChange={(e) => handleChange(e, setState, 'state')}
              onKeyPress={(e) => handleChange(e, setState, 'state')}
              onBlur={() => handleBlur({ 'state': state })}
            />
            <label>ZIP CODE</label>
            <input
              type="text"
              className="addressInput"
              placeholder='Add Zip'
              autoComplete="off"
              spellCheck="off" 
              autoCapitalize="off"
              value={zip}
              onChange={(e) => handleChange(e, setZip, 'zip')}
              onKeyPress={(e) => handleChange(e, setZip, 'zip')}
              onBlur={() => handleBlur({ 'zip': zip })}
            />
            <DropdownSelect 
              options={countryOptions}
              title={"COUNTRY"}
              placeholder={country || "Add Country"}
              onChange={handleCountryChange}
              filterOption={(option, input) => option.label.toLowerCase().includes(input.toLowerCase())} 
            />
            <DropdownSelect 
              options={continentOptions}
              title={"CONTINENT"}
              placeholder={continent || "Add Continent"}
              onChange={handleContinentChange}
              filterOption={(option, input) => option.label.toLowerCase().includes(input.toLowerCase())}
            />
            <label>PHONE NUMBER</label>
            <input
              type="text"
              className="addressInput"
              placeholder='Add Phone Number'
              autoComplete="off"
              spellCheck="off" 
              autoCapitalize="off"
              value={phone}
              onChange={(e) => handleChange(e, setPhone, 'phone_number')}
              onKeyPress={(e) => handleChange(e, setPhone, 'phone_number')}
              onBlur={() => handleBlur({ 'phone_number': phone })}
            />
            <label>EMAIL</label>
            <input
              type="text"
              className="addressInput"
              placeholder='Add Email'
              autoComplete="off"
              spellCheck="off" 
              autoCapitalize="off"
              value={email}
              onChange={(e) => handleChange(e, setEmail, 'email')}
              onKeyPress={(e) => handleChange(e, setEmail, 'email')}
              onBlur={() => handleBlur({ 'email': email })}
            />
            <label>LATITUDE</label>
            <input
              type="text"
              className="addressInput"
              placeholder='Add Latitude'
              autoComplete="off"
              spellCheck="off" 
              autoCapitalize="off"
              value={latitude}
              onChange={(e) => handleChange(e, setLatitude, 'latitude')}
              onKeyPress={(e) => handleChange(e, setLatitude, 'latitude')}
              onBlur={() => handleBlur({ 'latitude': latitude })}
            />
            <label>LONGITUDE</label>
            <input
              type="text"
              className="addressInput"
              placeholder='Add Longitude'
              autoComplete="off"
              spellCheck="off" 
              autoCapitalize="off"
              value={longitude}
              onChange={(e) => handleChange(e, setLongitude, 'longitude')}
              onKeyPress={(e) => handleChange(e, setLongitude, 'longitude')}
              onBlur={() => handleBlur({ 'longitude': longitude })}
            />
          </div>
        </div>
      </form>
    );
  }  

  const renderTees = () => {
    return (
      <div className="teesContainer">
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <h1>Tees</h1>
        </div>
        {
          courseDetails.tees && Object.keys(courseDetails.tees).length > 0 ?
          <table border="1">
            <thead>
              <tr>
                <th className="text">ID</th>
                <th className="text">Name</th>
                <th className="text">Gender</th>
                <th className="text">Par</th>
                <th className="text">Yards</th>
                <th className="text">Rating</th>
                <th className="text">Slope</th>
                <th className="text">Measure</th>
                <th className="text">Rating Front</th>
                <th className="text">Rating Back</th>
                <th className="text">Slope Front</th>
                <th className="text">Slope Back</th>
                <th className="text">Clone Tees</th>
              </tr>
            </thead>
            <tbody>
              {
                Object.keys(courseDetails.tees).map((tee, i) => {
                  return (
                    <tr key={i}>
                      <td className="text">{tee}</td>
                      <td>           
                        <input
                          type="text"
                          className="tableInput"
                          placeholder='Add Tee Name'
                          autoComplete="off"
                          spellCheck="off"
                          autoCapitalize="off"
                          value={courseDetails.tees[tee].name}
                          onChange={(e) => handleTableChange(e, 'tee', tee, 'name')}
                          onKeyPress={(e) => handleTableChange(e, 'tee', tee, 'name')}
                          onBlur={() => handleTableBlur('tee', tee, 'name')}
                        />
                      </td>
                      <td>
                        <select
                          className="tableInput"
                          value={courseDetails.tees[tee].gender}
                          onChange={(e) => handleTableChange(e, 'tee', tee, 'gender')}
                          onBlur={() => handleTableBlur('tee', tee, 'gender')}
                        >
                          <option value="men">Men</option>
                          <option value="ladies">Ladies</option>
                        </select>
                      </td>
                      <td className="text">{courseDetails.tees[tee].par}</td>
                      <td className="text">{courseDetails.tees[tee].yards}</td>
                      <td>
                        <input
                          type="text"
                          className="tableInput"
                          placeholder='Add Rating'
                          autoComplete="off"
                          spellCheck="off" 
                          autoCapitalize="off"
                          value={courseDetails.tees[tee].rating}
                          onChange={(e) => handleTableChange(e, 'tee', tee, 'rating')}
                          onKeyPress={(e) => handleTableChange(e, 'tee', tee, 'rating')}
                          onBlur={() => handleTableBlur('tee', tee, 'rating')}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="tableInput"
                          placeholder='Add Slope'
                          autoComplete="off"
                          spellCheck="off" 
                          autoCapitalize="off"
                          value={courseDetails.tees[tee].slope}
                          onChange={(e) => handleTableChange(e, 'tee', tee, 'slope')}
                          onKeyPress={(e) => handleTableChange(e, 'tee', tee, 'slope')}
                          onBlur={() => handleTableBlur('tee', tee, 'slope')}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="tableInput"
                          placeholder='Add Measure Unit'
                          autoComplete="off"
                          spellCheck="off" 
                          autoCapitalize="off"
                          value={courseDetails.tees[tee].measure_unit}
                          onChange={(e) => handleTableChange(e, 'tee', tee, 'measure_unit')}
                          onKeyPress={(e) => handleTableChange(e, 'tee', tee, 'measure_unit')}
                          onBlur={() => handleTableBlur('tee', tee, 'measure_unit')}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="tableInput"
                          placeholder='Add Rating Front'
                          autoComplete="off"
                          spellCheck="off" 
                          autoCapitalize="off"
                          value={courseDetails.tees[tee].rating_front}
                          onChange={(e) => handleTableChange(e, 'tee', tee, 'rating_front')}
                          onKeyPress={(e) => handleTableChange(e, 'tee', tee, 'rating_front')}
                          onBlur={() => handleTableBlur('tee', tee, 'rating_front')}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="tableInput"
                          placeholder='Add Rating Back'
                          autoComplete="off"
                          spellCheck="off" 
                          autoCapitalize="off"
                          value={courseDetails.tees[tee].rating_back}
                          onChange={(e) => handleTableChange(e, 'tee', tee, 'rating_back')}
                          onKeyPress={(e) => handleTableChange(e, 'tee', tee, 'rating_back')}
                          onBlur={() => handleTableBlur('tee', tee, 'rating_back')}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="tableInput"
                          placeholder='Add Slope Front'
                          autoComplete="off"
                          spellCheck="off" 
                          autoCapitalize="off"
                          value={courseDetails.tees[tee].slope_front}
                          onChange={(e) => handleTableChange(e, 'tee', tee, 'slope_front')}
                          onKeyPress={(e) => handleTableChange(e, 'tee', tee, 'slope_front')}
                          onBlur={() => handleTableBlur('tee', tee, 'slope_front')}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="tableInput"
                          placeholder='Add Slope Back'
                          autoComplete="off"
                          spellCheck="off" 
                          autoCapitalize="off"
                          value={courseDetails.tees[tee].slope_back}
                          onChange={(e) => handleTableChange(e, 'tee', tee, 'slope_back')}
                          onKeyPress={(e) => handleTableChange(e, 'tee', tee, 'slope_back')}
                          onBlur={() => handleTableBlur('tee', tee, 'slope_back')}
                        />
                      </td>
                      <td>
                        <button className="addTeeButton" onClick={() => handleCopyTees(tee)}>Create Clone</button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table> :
          null
        }
      </div>
    );
  };  
  
  const renderHoles = () => {
    return (
      <div className="teesContainer">
        <h1>Scorecards</h1>
        {
          courseDetails.tees && Object.keys(courseDetails.tees).length > 0 ?
          Object.keys(courseDetails.tees).map((teeId, i) => {
            const tee = courseDetails.tees[teeId];
            return (
              <div key={i} className="scoreCardContainer">
                <h2>{`${tee.name}`}</h2>
                <p className="scorecardSubtext">{`${tee.gender} | rating: ${tee.rating} | slope: ${tee.slope}`}</p>
                <div className="tableContainer">
                  <table border="1">
                    <tbody>
                      <tr>
                        <th className="text">Hole</th>
                        {
                          tee.holes.map((hole, j) => {
                            return (
                              <td key={j} className="text">
                                <input
                                  type="text"
                                  className="tableInput"
                                  placeholder='Hole Number'
                                  autoComplete="off"
                                  spellCheck="off"
                                  autoCapitalize="off"
                                  value={hole.hole}
                                  onChange={(e) => handleTableChange(e, 'hole', teeId, 'hole', j)}
                                  onKeyPress={(e) => handleTableChange(e, 'hole', teeId, 'hole', j)}
                                  onBlur={() => handleTableBlur('hole', teeId, 'hole', j)}
                                />
                              </td>
                            )
                          })
                        }
                        <td className="text">Totals</td>
                      </tr>
                      <tr>
                        <th className="text">Par</th>
                        {
                          tee.holes.map((hole, j) => {
                            return (
                              <td key={j} className="text">
                                <input
                                  type="text"
                                  className="tableInput"
                                  placeholder='Par'
                                  autoComplete="off"
                                  spellCheck="off"
                                  autoCapitalize="off"
                                  value={hole.par}
                                  onChange={(e) => handleTableChange(e, 'hole', teeId, 'par', j)}
                                  onKeyPress={(e) => handleTableChange(e, 'hole', teeId, 'par', j)}
                                  onBlur={() => handleTableBlur('hole', teeId, 'par', j)}
                                />
                              </td>
                            )
                          })
                        }
                        <td className="text">{tee.holes.reduce((acc, hole) => acc + parseInt(hole.par, 10), 0)}</td>
                      </tr>
                      <tr>
                        <th className="text">Yards</th>
                        {
                          tee.holes.map((hole, j) => {
                            return (
                              <td key={j} className="text">
                                <input
                                  type="text"
                                  className="tableInput"
                                  placeholder='Yards'
                                  autoComplete="off"
                                  spellCheck="off"
                                  autoCapitalize="off"
                                  value={hole.yards}
                                  onChange={(e) => handleTableChange(e, 'hole', teeId, 'yards', j)}
                                  onKeyPress={(e) => handleTableChange(e, 'hole', teeId, 'yards', j)}
                                  onBlur={() => handleTableBlur('hole', teeId, 'yards', j)}
                                />
                              </td>
                            )
                          })
                        }
                        <td className="text">{tee.holes.reduce((acc, hole) => acc + parseInt(hole.yards, 10), 0)}</td>
                      </tr>
                      <tr>
                        <th className="text">Handicap</th>
                        {
                          tee.holes.map((hole, j) => {
                            return (
                              <td key={j} className="text">
                                <input
                                  type="text"
                                  className="tableInput"
                                  placeholder='Handicap'
                                  autoComplete="off"
                                  spellCheck="off"
                                  autoCapitalize="off"
                                  value={hole.handicap}
                                  onChange={(e) => handleTableChange(e, 'hole', teeId, 'handicap', j)}
                                  onKeyPress={(e) => handleTableChange(e, 'hole', teeId, 'handicap', j)}
                                  onBlur={() => handleTableBlur('hole', teeId, 'handicap', j)}
                                />
                              </td>
                            )
                          })
                        }
                        <td className="text">-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )
          }) :
          null
        }
      </div>
    );
  };

  if (!user) {
    return (
      <div>
        <SignIn isMobile={isMobile}/>
      </div>
    )
  }

  return (
    <div>
      {
        isLoading ? <LoadingSpinner /> :
        <div className={isMobile ? 'page-mobile' : 'page'}>
          {renderHeader()}
          {renderTees()}
          {renderHoles()}
          <ErrorModal isOpen={!!error} message={extractErrorMessage(error)} onClose={closeErrorModal} />
          {bannerVisible && <div className="banner">{bannerMessage}</div>}
        </div>
      }
    </div>

  );
};

export default CourseDetail;
