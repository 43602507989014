import React, { useState, useEffect } from 'react';
import { post } from '../api';
import LoadingSpinner from '../components/LoadingSpinner';

import { useGlobalState } from '../context/GlobalState';

import './SignIn.css';



const SignIn = ({ isMobile }) => {
  const { user, signInUser } = useGlobalState();

  const [isLoading, setIsLoading] = useState(true);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    checkForUserToken();
  }, [])

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async e => {
    e.preventDefault(); // Prevents the default form submission behavior
    setIsLoading(true);
    const userData = await post('/cmsui/signin', { username, password })
      .catch(err => {
        console.log({err});
      });  

    // localStorage.setItem('user', userData);
    localStorage.setItem('token', userData.token);
    localStorage.setItem('id', userData._id.toString());
    // const userId = localStorage.getItem('id');
    // const token = localStorage.getItem('token');
    // localStorage.removeItem('token');
    // localStorage.removeItem('id');
    signInUser(userData);
    // store userdata in client global state
  };

  const checkForUserToken = async () => {
    if (user) return;
    const userId = localStorage.getItem('id');
    const token = localStorage.getItem('token');

    if (!token || !userId) {
      setIsLoading(false);
      return;
    }

    const payload = { 
      user: userId,
      token,
    };

    const userData = await post('/cmsui/signinauto', payload)
      .catch(err => {
        console.log({err});
      });  

    if (!userData) {
      setIsLoading(false);
      return;
    }

    // localStorage.setItem('user', userData);
    localStorage.setItem('token', userData.token);
    localStorage.setItem('id', userData._id.toString());

    signInUser(userData);
  };

  
  if (isLoading) return <LoadingSpinner />
  return (
    <div className={isMobile ? "signin-page-mobile" : "signin-page"}>
      <form className="column">
        <input type="form" className="formInput" placeholder="username" onChange={handleUsernameChange} />
        <input type="password" className="formInput" placeholder="password" onChange={handlePasswordChange} />
        <button className="button" onClick={(e) => handleSubmit(e)}>Sign In</button>
      </form>
    </div>

  );
};

export default SignIn;