import React from 'react';
import './ActiveToggle.css';

function ActiveToggle({ isActive, onChange }) {
  return (
    <div className="button-toggle">
      <button
        type="button"
        className={`toggle-button ${isActive ? 'active-true' : ''}`}
        onClick={() => onChange(true)}
      >
        true
      </button>
      <button
        type="button"
        className={`toggle-button ${!isActive ? 'active-false' : ''}`}
        onClick={() => onChange(false)}
      >
        false
      </button>
    </div>
  );
}

export default ActiveToggle;
