import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { post } from '../api';

// import { useGlobalState } from '../context/GlobalState';

import './CourseList.css';

const CourseList = ({ isMobile }) => {
  let navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [course, setCourse] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  // on load get courses to fill list page
  useEffect(() => {
    searchCourses();
  }, []);

  // allow for course search
  const searchCourses = async (e) => {
    if (e) {
      e.persist(); // This will ensure the event object is not reused and is available during the async operation
      e.preventDefault(); // This will prevent the default form submission behavior
    }

    if (isLoading) return;
    setSearchResults([]);
    setIsLoading(true);

    const payload = {
      search: course,
      limit: 100,
      offset: 0,
    }

    const courseData = await post('/cmsui/search', payload)
      .catch(err => {
        console.log({err});
      });  

    if (courseData) setSearchResults(courseData);
    setIsLoading(false);
  };

  const handleCourseChange = (event) => {
    setCourse(event.target.value);
  };

  const goToCourseDetailPage = (courseNumber) => {
    navigate(`/course/${courseNumber}`); // Navigate to the detail page with the courseNumber
  };
 
  const createCourse = async (event, holes) => {    
    event.preventDefault();
  
    const payload = {
      holes,
    }

    const newCourse = await post('/cmsui/create_course', payload)
      .catch(err => {
        console.log({err});
      });  

    if (newCourse && newCourse.course) goToCourseDetailPage(newCourse.course)
  };

  return (
    <div className={isMobile ? 'search-page-mobile' : 'search-page'}>
      <div>
        <form>
          <input className="formInput" style={{ marginRight: '1em' }} type="form" placeholder="Search Course" onChange={handleCourseChange} />
          <button onClick={(e) => searchCourses(e)}>Search</button>
        </form>
      </div>
      <div>
        <form>
          <button className="createCourseButton" onClick={(e) => createCourse(e, 9)}>Create 9 Hole Course</button>
          <button className="createCourseButton" onClick={(e) => createCourse(e, 18)}>Create 18 Hole Course</button>
        </form>
      </div>
      <div className="table-container">
        <p className="text">{`Showing ${searchResults.length} courses`}</p>
        {
          searchResults.length > 0
          ?
          <table border="1">
            <thead>
              <tr>
                <th className="text">Course Name</th>
                <th className="text">Active</th>
                <th className="text">Holes</th>
                <th className="text">Tees</th>
                <th className="text">City</th>
                <th className="text">State</th>
                <th className="text">Country</th>
                <th className="text">Website</th>
              </tr>
            </thead>
            <tbody>
              {
                searchResults.map((course, i) => {
                  return (
                    <tr key={i} onClick={() => goToCourseDetailPage(course._id)}>
                      <td className="text">{course.name}</td>
                      <td className="text">{course.active ? 'true' : 'false'}</td>
                      <td className="text">{course.holes}</td>
                      <td className="text">{course.tees_count}</td>
                      <td className="text">{course.city}</td>
                      <td className="text">{course.state}</td>
                      <td className="text">{course.country}</td>
                      <td className="text"><a className="website" href={course.website} target="_blank">{course.website}</a></td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          :
          null
        }
      </div>
    </div>
  );
};

export default CourseList;