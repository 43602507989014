import React from 'react';
import Select from 'react-select';

const DropdownSelect = ({ options, title, placeholder, onChange, filterOption }) => {
    const customStyles = {
      option: (provided, state) => ({
          ...provided,
          color: 'black',
      }),
      singleValue: (provided, state) => ({
          ...provided,
          color: 'black',
      }),
      input: (provided, state) => ({
          ...provided,
          color: 'black',
      }),
      placeholder: (provided, state) => ({
          ...provided,
          color: 'black',
      }),
      control: (provided, state) => ({
          ...provided,
          color: 'black',
          borderColor: state.isFocused ? '#666' : '#ccc',
          boxShadow: state.isFocused ? '0 0 0 1px #666' : 'none',
      })
    };

    return (
        <div>
            <label>{title}</label>
            <Select
                options={options}
                className="addressInput"
                placeholder={placeholder}
                styles={customStyles}
                onChange={onChange}
                filterOption={filterOption}
                isClearable={true}
                isSearchable={true}
            />
        </div>
    );
};

export default DropdownSelect;
