import React, { useState, useEffect } from 'react';

function ErrorModal({ isOpen, message, onClose }) {
  if (!isOpen) return null;

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <div style={{
        padding: 20,
        background: 'white',
        borderRadius: 5,
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        zIndex: 1000,
        minWidth: 300,
        maxWidth: '80%',
        maxHeight: '80%',
        overflowY: 'auto',
        wordWrap: 'break-word'
      }}>
        <h2 style={{ color: '#222' }}>Error</h2>
        <p style={{ 
          color: '#222',
          marginBottom: 20,
          lineHeight: '1.5'
        }}>
          {message}
        </p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
}

export default ErrorModal;
