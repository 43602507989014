
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useGlobalState } from '../context/GlobalState';

import logo from '../logo.png';

import './Header.css';

const Header = ({ isMobile }) => {
  const { user, userSignOut } = useGlobalState();

  const navigate = useNavigate();

  // const goToProfile = () => {
  //   navigate(`/profile/`);
  // };

  const goToDashboard = () => {
    navigate(`/dashboard/`);
  }

  return (
    <div className="header">
      <div className="left">
        <span className='title' onClick={() => navigate(`/`)}>
          <img src={logo} className={isMobile ? 'logo-mobile' : "logo"} alt="logo" />
        </span>
      </div>
      {
        user
        ?
        <div className="right">
          <span className='terms' onClick={() => goToDashboard()}>
            <p>Dashboard</p>
          </span>
          <span className='terms' onClick={() => userSignOut()}>
            <p>Sign Out</p>
            {/* <p>{user.username}</p> */}
          </span>
        </div>
        :
        null
      }
    </div>
  )
}


export default Header;