import React from 'react';
import './DashboardDetails.css';

const titleMap = {
  userCount: {
    title: 'Total Users',
    prefix: '',
  },
  inviteCount: {
    title: 'Pending Invites',
    prefix: '',
  },
  roundsCount: {
    title: 'Rounds Played',
    prefix: '',
  },
  totalBuyIns: {
    title: 'Cumulative Buyins',
    prefix: '$',
  },
  totalDeposits: {
    title: 'User Deposits',
    prefix: '$',
  },
  totalBonuses: {
    title: 'Sign Up Bonuses Rewarded',
    prefix: '$',
  },
  totalFees: {
    title: 'Fees Collected',
    prefix: '$',
  },
  totalCumulativeAmount: {
    title: 'Capital in Circulation',
    prefix: '$',
  },
}

function DashboardDetails({ prop, value }) {
  console.log({prop, value});
  return (
    <div key={prop} className='stat-box'>
      <p className='stat-title'>{titleMap[prop].title}</p>
      <p className='stat-value'>{`${titleMap[prop].prefix}${value}`}</p>
    </div>
  );
}

export default DashboardDetails;
